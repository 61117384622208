import React from "react"
import Layout from "../components/layout"
import Card from '../components/card'
import { graphql } from "gatsby"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={[`website`, `application`, `react`, `redux`, `vue`, `vuex`, `frontend`, `developer`, `javascript`, `php`, `html`, `css`]} />
    <section className="hero">
      <div className="hero__inner container">
        <h1>{data.site.siteMetadata.description}</h1>
        <a href="#work" className="btn btn--lg">My Latest Work</a>
      </div>
    </section>

    <section name="work" className="work container">
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Card       
          key={node.id}
          node={node} 
        />
      ))}
    </section>

    <section className="clients container">
        <h2 className="mb-12">Additional Clients</h2>
        <div className="clients__inner">
          <div className="clients__client">
            <img src="../a-and-w.svg" alt="A&amp;W" />
          </div>
          <div className="clients__client">
            <img src="../ag-hair.svg" alt="AG Hair" />
          </div>
          <div className="clients__client">
            <img src="../avigilon.svg" alt="Avigilon" />
          </div>
          <div className="clients__client">
            <img src="../rocky-mountaineer.svg" alt="Rocky Mountaineer" />
          </div>
          <div className="clients__client">
            <img src="../playland.svg" alt="Playland" />
          </div>
          <div className="clients__client">
            <img src="../bell.svg" alt="Bell" />
          </div>
          <div className="clients__client">
            <img src="../bc-hydro.svg" alt="BC Hydro" />
          </div>
          <div className="clients__client">
            <img src="../shaw.svg" alt="Shaw" />
          </div>
          <div className="clients__client">
            <img src="../norco.svg" alt="Norco Bikes" />
          </div>
          <div className="clients__client">
            <img src="../axiom.svg" alt="Axiom Performance Gear" />
          </div>
        </div>
    </section>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            tags
            link
            linkLabel
            logo
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
