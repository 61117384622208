import PropTypes from "prop-types"
import React from "react"
import { Link } from 'gatsby'

const Card = ({ node }) => {
  const { 
    title,
    logo,
    description,
    tags,
  } = node.frontmatter;
  return (
    <section 
      className="card" 
    >
      <div className="card__logo">
        <img src={logo} alt={title} className="block" />
      </div>
      <h2>
        {title}
      </h2>
      <p>{description}</p>
      <p className="card__tags"><strong>Tech: </strong>{tags}</p>
      <Link
        className="btn"
        to={node.fields.slug}
      >
        More Details
      </Link>
    </section>
    
  )
}

Card.propTypes = {
  node: PropTypes.object,
}

Card.defaultProps = {
  node: null,
}

export default Card
